import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "react-lazy-load-image-component/src/effects/blur.css"

import mouseWheel from "../../images/mouse-wheel.gif"

const JumboLoans = () => (
  <Layout>
    <SEO
      title="JUMBO Loans"
      description="Big loan? No problem. Our unique JUMBO programs might be the perfect fit."
    />
    <div className="container-fluid hero-section">
      <div className="row">
        <div className="col-md-5 vertical-align d-none-mobile">
          <div className="brandstamp">
            <h1>JUMBO Loans</h1>
            <p className=" pb-3 pt-3">
              Big loan? No problem. Our unique JUMBO programs might be the
              perfect fit.
              <br />
            </p>
          </div>
        </div>
        <div className="col-md-7 jl-hero-img vertical-align">
          <div className="brandstamp-mobile text-white">
            <h1>JUMBO Loans</h1>
            <p className="mb-5">
              Big loan? No problem. Our unique JUMBO programs might be the
              perfect fit.
              <br />
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-12 text-center scroll-down">
      <img className="mouse-wheel" src={mouseWheel} alt="Scroll Down" />
    </div>

    <div className="container jl-wrapper mt-5">
      <div className="row flex-column-reverse flex-md-row">
        <div className="col-lg-8">
          <div className="apply-container">
            <p className="mb-3">
              The only true Jumbo loan that is easy to close! Among the most
              competitive 30-year fixed rates in the industry,we provide jumbo
              loans with clear, hassle-free guidelines.
            </p>
            <ul className="mb-5">
              <li>Eligible for primary, secondary and investment properties</li>
              <li>FICOs as low as 680</li>
              <li>Loan amounts up to $3 million</li>
              <li>Fixed rate and ARM loans available</li>
            </ul>
            <p>New Jumbo 90% with no Mortgage Insurance</p>
            <p>
              This unique jumbo loan program allows homebuyers to take out a
              mortgage of up to $1.5 million on a 90% loan-to-value mortgage.
              The program requires a 720 credit score and has reserve
              requirements, but it enables jumbo loan borrowers to lower their
              down payment or increase their buying power. Because we don’t
              require mortgage insurance, it also reduces the monthly payment.
            </p>
            <ul>
              <li>30-year fixed</li>
              <li>720+ FICO</li>
              <li>Up to 90% LTV</li>
              <li>No mortgage insurance</li>
              <li>Primary homes</li>
              <li>43% maximum DTI</li>
            </ul>
          </div>
          <div className="mt-5">
            <Link
              to="/loan-programs"
              className="btn-global-inverted mr-3 text-white"
              type="button"
            >
              Back to Loan Programs
            </Link>
          </div>
        </div>
        <div className="col-lg-4 text-white">
          <h4 style={{ color: "#0658ff" }} className="mt-3">
            More Loan Options
          </h4>
          <ul>
            <li>
              <Link to="/loan-programs/jumbo-loans">JUMBO Loans</Link>
            </li>
            <li>
              <Link to="/loan-programs/usda-financing">USDA Financing</Link>
            </li>
            <li>
              <Link to="/loan-programs/doctor-program">Doctor Program</Link>
            </li>
            <li>
              <Link to="/loan-programs/exclusive-high-balance-nationwide">
                Colorado High Balance
              </Link>
            </li>
            <li>
              <Link to="/loan-programs/fha-home-loans">FHA Home Loans</Link>
            </li>
            <li>
              <Link to="/loan-programs/va-home-loans">VA Home Loans</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div className="apply-section">
      <div className="protruded-gradient"></div>
      <div className="container">
        <div className="apply-container">
          <div className="row">
            <div className="col-md-10">
              <div>
                <h2 className="mb-2 mt-3">
                  Let's get started
                  <br />
                </h2>
                <p className="pb-4">
                  Getting pre-approved can be quick and easy. Start the process
                  today!
                  <br />
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <Link to="https://plus.preapp1003.com/Darlene-Franklin" className="btn-global w-100 mb-3" type="button">
                Apply Now
              </Link>
              <Link
                to="/loan-process"
                className="btn-global-inverted w-100 text-center"
                type="button"
                style={{ padding: "10px 20px" }}
              >
                Our Process
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default JumboLoans
